import { Link } from "gatsby"
import React from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/SEO"
import Images from "../components/Images"

const ClassesPage: React.FC = () => (
  <Layout activePage="taster-sessions">
    <SEO title="Taster Sessions" />
    <h1 className="page-title">Pottery Taster Sessions</h1>
    <div className="divider divider--spacer" />
    <p>Join us at our studio for an enjoyable pottery taster session! Held mainly on weekends, these sessions are ideal for exploring your creativity and having a great time with friends or family.</p>
    <p>In the three-hour session, we'll teach you basic hand-building techniques to make your own decorative piece, whether it's shallow bowl, mug, or vase. Then, experience the excitement of the potter's wheel. Personalize your creations with colorful slips before we handle firing and glazing.</p>
    <div className="card__container">
      <div className="card" style={{borderRadius: "50px"}}>
        <Images image="howtothrow" />
      </div>
      <div className="card" style={{borderRadius: "50px"}}>
        <Images image="howtohandbuild" />
      </div>
    </div>
    <p>Your finished pieces will be ready for collection around 4 - 6 weeks later, at no extra charge. Want to make more? We're happy to assist for an additional fee based on item size and weight.</p>
    <p>Choose from morning (9:30 am - 12:30 pm) or afternoon (2:00 pm - 5:00 pm) sessions on weekends. Enjoy a one-on-one session for £135, or bring friends or family (2 - 4 people) for just £95 each. Each session is tailored to ensure personalized attention, with your own reserved slot.</p>
    <p>Have specific ideas? Let us know, and we'll customize a session to suit you.</p>
    <p>Excited to get started? Contact us to check availability and reserve your spot. Reach us <a href="tel:01406425866">by phone</a> or email at <a href="mailto:enquiries@greenfieldpottery.co.uk">enquiries@greenfieldpottery.co.uk</a>. Payment options include bank transfer, cash, or card.</p>
    <p>Before getting started, please review our <Link to="/terms-and-conditions">terms and conditions</Link>. We can't wait to see your creations!</p>
    {/* <p>
      Our pottery taster sessions are held at our studio mostly at at the
      weekend, by prior arrangement.
    </p>
    <p>
      These are fun, two hour beginner sessions in which we will show you some
      basic hand-building techniques to create a decorative piece, such as a
      shallow dish. This is followed by having a go on the potters wheel. You
      can then choose your best thrown pot to decorate with coloured slips. The
      two items will be fired and glazed by us which can be collected at a later
      date. We will notify you when they are ready, approximately 4 - 6 weeks
      following your session. This is included in the price. Additional pots
      that you have made can be decorated, fired and glazed for an additional
      charge based upon the size/weight of the item.
    </p>
    <p>
      You will have your own slot for the session. We do not mix groups to
      ensure that each person gets the guidance that they need. Taster sessions
      are £55 for an individual 1-to-1 session, £45 each for two people or £40
      per person for a group of three or more. If you have any questions or wish
      to book, give us a{" "}
      <a
        href="tel:01406425866"
        aria-label="call us on 01406 425 866 (use this link to open the phone number)"
      >
        call
      </a>{" "}
      or email us at{" "}
      <a
        href="mailto:enquiries@greenfieldpottery.co.uk"
        aria-label="enquiries@greenfieldpottery.co.uk (opens in your email app)"
      >
        enquiries@greenfieldpottery.co.uk
      </a>
      . Payment can be made by bank transfer, or in person by cash or card.
    </p>
    <p>
      Please see our{" "}
      <Link to="/terms-and-conditions">terms and conditions</Link> before
      booking.
    </p>
    <div className="divider divider--spacer" />
    <h2 className="section-header">Pottery Workshops</h2>
    <p>
      We offer half-day and full-day pottery workshops, suitable for complete
      beginners or those that have some experience working with clay.
    </p>
    <p>
      During these workshops, you will be able to try out a variety of pottery
      making techniques, including throwing on the wheel and hand-building
      methods. What you would like to focus on is completely up to you. You can
      be as creative as you like, and can decorate your work with coloured slips
      if you wish.
    </p>
    <div className="card__container">
      <div className="card">
        <Images image="howtothrow" />
        <div className="card__body">
          <h3 className="card__title">Learning how to throw</h3>
          <p className="card__text">You have the opportunity to learn:</p>
          <ul className="card__list">
            <li>How to prepare the clay</li>
            <li>Centering the clay</li>
            <li>Making a cylinder or bowl</li>
            <li>Turning and finishing</li>
            <li>Pulling and adding handles</li>
            <li>Making spouts and lids</li>
          </ul>
        </div>
      </div>
      <div className="card">
        <Images image="howtohandbuild" />
        <div className="card__body">
          <h3 className="card__title">Learning how to hand build</h3>
          <p className="card__text">You have the opportunity to learn:</p>
          <ul className="card__list">
            <li>How to prepare the clay</li>
            <li>Coiling techniques</li>
            <li>Slab building techniques</li>
            <li>Thumb pots and tiles</li>
            <li>The use of moulds</li>
            <li>Exploring mark making, patterns and decorations</li>
          </ul>
        </div>
      </div>
    </div>
    <p>
      We can accommodate up to four people in a workshop, and we run sessions
      during the week and at the weekend. The cost of the half-day workshop is
      £100 for an individual 1-to-1 session, or £75 each for groups of two or
      more. The cost of the full-day workshop is £185 for an individual 1-to-1
      session, or £145 each for groups of two or more.
    </p>
    <p>
      If you have any questions or wish to book, give us a{" "}
      <a
        href="tel:01406425866"
        aria-label="call us on 01406 425 866 (use this link to open the phone number)"
      >
        call
      </a>{" "}
      or email us at{" "}
      <a
        href="mailto:enquiries@greenfieldpottery.co.uk"
        aria-label="enquiries@greenfieldpottery.co.uk (opens in your email app)"
      >
        enquiries@greenfieldpottery.co.uk
      </a>
      .
    </p> */}
{/*     <div className="divider divider--spacer" /> */}
    {/* <h2 className="section-header">Pottery Lessons</h2>
    <p>
      We offer courses for beginners and people with some experience. During the
      course you will have to opportunity to learn a variety of different
      skills. Your time will be spent learning a range of hand-building
      techniques such as coiling and slab building, as well as some simple
      decorating and glazing techniques. You will also be offered to learn some
      basic techniques on how to prepare clay and throw on the potters wheel.
    </p>
    <p>
      We currently run courses on Tuesdays and Wednesdays at <b>10am to 12pm</b>
      , <b>2pm to 4pm</b> and <b>7pm to 9pm</b> depending on demand.
    </p>
    <p>
      There is a maximum class size of four people, which ensures that all
      individuals are given enough tuition to learn and develop their skills.
    </p>
    <p>
      Each block of teaching consists of eight weekly lessons and costs £190.
      Please ensure you are able to attend all of the sessions to get the most
      out of the course. Payment can be made by either bank transfer, or in
      person by cash or card.
    </p>
    <p>
      When starting the course, we will provide you with a quantity of clay
      which should be enough to complete the course. You can purchase additional
      clay throughout the course if needed. This also includes all materials,
      such as coloured slips, glazes, use of tools, equipment and gas firings.
    </p>
    <p>
      All of our courses and sessions are subject to our{" "}
      <Link to="/terms-and-conditions">terms and conditions</Link>, which you
      can find <Link to="/terms-and-conditions">here</Link>.
    </p>
    <div className="divider divider--spacer" /> */}
{/*     <h2 className="section-header">Groups</h2>
    <p>
      We can normally accommodate up to four people, but if you would like to
      attend in a larger group please <Link to="/contact-us">contact us</Link>{" "}
      with your requirements.
    </p> */}
    {/* 
    <div className="divider divider--spacer" />
    <h2 className="section-header">Gift Vouchers</h2>
    <p>
      Our taster sessions are available to purchase as a gift voucher. Please{" "}
      <Link to="/contact-us">contact us</Link> for details.
    </p>
    */}
  </Layout>
)

export default ClassesPage
